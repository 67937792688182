<template>
  <div>
    <Container class="bg-accent">
      <Navbar />

      <div class="pt-10 relative">
        <h1 class="text-center">Contact Us</h1>
        <div class="flex justify-center items-center mt-8">
          <Location />
          <h5 class="text-3xl text-primary ml-6">
            10, Hughes Avenue, Alagomeji, Yaba Lagos.
          </h5>
        </div>
        <div class="h-900 -mt-48 relative -bottom-64">
          <iframe
            class="rounded-lg w-full h-900"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.1745872350566!2d3.3752413148223033!3d6.499569325244167!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8c61bb1adb73%3A0x248670b10f8e5955!2s10%20Hughes%20Ave%2C%20Alagomeji-Yaba%20100001%2C%20Lagos!5e0!3m2!1sen!2sng!4v1619652991759!5m2!1sen!2sng"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </Container>
    <Container class="bg-white pt-80 pb-14">
      <div>
        <h5 class="text-3xl text-grey-350">Support</h5>
        <p class="text-xl text-grey-450 mt-4">Hello@ydev.com</p>
      </div>
    </Container>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/blocks/Navbar.vue";
import Container from "@/components/blocks/Container";
import Location from "../../public/svg/location.svg";
import Footer from "@/components/blocks/Footer";

export default {
  name: "ContactUs",
  components: {
    Navbar,
    Container,
    Location,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
</style>